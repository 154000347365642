@font-face {
    font-family: "paybooc";
    font-weight: normal;
    src: url("../fonts/paybooc/paybooc-Medium.ttf");
  }

  @font-face {
    font-family: "paybooc";
    font-weight:100;
    src: url("../fonts/paybooc/paybooc-Light.ttf");
  }

  @font-face {
    font-family: "paybooc";
    font-weight: bold;
    src: url("../fonts/paybooc/paybooc-Bold.ttf");
  }

  @font-face {
    font-family: "paybooc";
    font-weight: bolder;
    src: url("../fonts/paybooc/paybooc-ExtraBold.ttf");
  }

  .ad-resource-container {
    position: relative;
    width: 100%; /* 원하는 너비 */
}

.ad-resource-container:before {
    content: "";
    display: block;
    padding-top: 66.666%; /* 1:2 비율 */
}
 
.ad-resource {
    object-fit:cover;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    display: block;
    width: 100%;
    height: 100%;
}